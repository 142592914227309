<template>
    <div v-if="optionsEnabled">
        <div v-for="(group, index) in groups" :key="index" :class="{ 'mt-4px mt-md-8px': index != 0 }">
            <v-select v-model="choose[index]" dense outlined hide-details :items="maps(index)" item-text="value" item-value="value" :placeholder="group" @change="input(index)"></v-select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Array, default: () => [] }, // carts
        product: { type: Object, default: null },
        sidebar: { type: Boolean, default: false },
        deliveryType: { type: String, default: "single" }
    },
    data: () => ({
        choose: [],
    }),
    computed: {
        options() {
            return this.product?.options || [];
        },
        optionsEnabled() {
            return this.product?.optionsEnabled || false;
        },
        groups() {
            var groups = [];
            if (this.options[0]) {
                var names = this.options[0].name.split("/");
                for (var i in names) {
                    groups.push(names[i].split(":")[0]?.trim?.());
                }
            }
            return groups;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        },
    },
    methods: {
        init() {
            try {
                for (var i in this.groups) {
                    if (!this.choose[i]) this.$set(this.choose, i, "");
                }
            } catch (error) {
                console.error(error);
            }
        },
        maps(index) {
            var maps = [];
            var name = "";
            for (var i = 0; i < index; i++) {
                name = name + `${this.groups[i]}: ${this.choose[i]} / `;
            }

            for (var i in this.options) {
                var option = this.options[i];
                if (option.name.indexOf(name) == 0) {
                    var value = option.value.split(" / ")[index];
                    if (!maps.find((map) => map.value == value)) {
                        maps.push({ value, option: index + 1 == this.groups.length ? option : { stock: 1 } });
                    }
                }
            }
            return maps;
        },
        input(index) {
            if (this.groups.length == index + 1) {
                var option = this.options.find((option) => option.value == this.choose.join(" / "));
                if (option && !this.value.find((cart) => cart._option == option._id)) {
                    const cart = {
                        _product: this.product._id,
                        _option: option._id,
                        product: this.product,
                        name: option.name,
                        price: option.price,
                        salePrice: option.salePrice,
                        discountPrice: option.discountPrice,
                        stock: option.stock,
                        amount: 1,
                        deliveryType: this.deliveryType
                    };
                    this.$emit("input", [...this.value, cart]);
                }
            } else {
                for (var i in this.choose) {
                    if (index < i) this.choose[i] = "";
                }
            }
        },
    },
};
</script>
