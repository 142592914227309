<template>
    <v-card tile v-bind="{ to }" class="before__no-background fill-height">
        <div class="py-16px fill-height">
            <v-row align-md="center" class="ma-n8px fill-height">
                <v-col v-if="$slots['select']" cols="12" md="auto" class="px-8px pt-8px pb-0">
                    <slot name="select" />
                </v-col>
                <v-col cols="auto" class="pa-8px">
                    <v-card :disabled="isSoldOut" rounded="md">
                        <v-img v-bind="{ src }" width="100" height="100">
                            <v-fade-transition>
                                <v-overlay v-show="!src" absolute>
                                    <v-icon>mdi-image-broken</v-icon>
                                </v-overlay>
                            </v-fade-transition>
                        </v-img>
                    </v-card>
                </v-col>
                <v-col class="pa-8px">
                    <v-card color="transparent" :disabled="isSoldOut">
                        <div class="mb-4px">
                            <v-chip v-if="isSoldOut" x-small color="grey">품절</v-chip>
                        </div>
                        <div v-if="product?.brand?.name" class="txt txt--xs txt--light mb-4px">{{ product?.brand?.name || "" }}</div>
                        <div class="txt txt--sm txt--dark font-weight-medium mb-12px">{{ product?.name }}</div>
                        <div class="d-flex align-center font-size-18 font-size-md-20">
                            <strong v-if="product?.discountRate" class="primary--text mr-8px"> {{ product?.discountRate }}% </strong>
                            <strong class="d-inline-flex align-center">{{ product?.salePrice?.format?.() }}<small class="font-size-14 font-weight-regular pl-2px">원</small></strong>
                            <span v-if="product?.discountRate" class=" font-size-14 text-decoration-line-through grey--text text--lighten-1 ml-8px">{{ product?.price?.format?.() }}원</span>
                        </div>
                    </v-card>
                </v-col>
                <v-col v-if="$slots['actions']" cols="12" md="auto" class="pa-8px">
                    <div class="w-md-150px">
                        <slot name="actions" />
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-divider v-if="!hideDivider" />
    </v-card>
</template>

<script>
import LikeBtn from "../../like/like-btn.vue";

export default {
    components: {
        LikeBtn,
    },
    props: {
        product: { type: Object, default: () => ({}) },

        isLink: { type: Boolean, default: false },
        isSoldOut: { type: Boolean, default: false },
        hideDivider: { type: Boolean, default: false },
    },
    computed: {
        src() {
            let { _id, thumb } = this.product || {};
            if (_id && thumb) return `/res/shop/products/${_id}/${thumb}`;
        },
        to() {
            if (this.isLink) return `/shop/products/${this.product?._id}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.before__no-background:before {
    background: initial !important;
}
</style>
