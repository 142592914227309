<template>
    <div class="overflow-hidden">
        <v-radio-group :value="deliveryType" v-bind="{ ...attrs_controls_common }" @change="input">
            <v-radio label="단건배송" value="single"></v-radio>
            <v-radio label="정기배송" value="regular"></v-radio>
        </v-radio-group>
    </div>
</template>

<script>
import { attrs_controls_common } from "@/assets/variables";

export default {
    props: {
        value: { type: Array, default: () => [] }, // carts
        product: { type: Object, default: null },
        deliveryType: { type: String, default: "single" }
    },
    computed: {},
    data() {
        return {
            attrs_controls_common,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        input(type) {
            console.log(type)
            for (let i = 0; i < this.value.length; i++) {
                this.value[i].deliveryType = type;
            }
            this.$emit("deliveryType", type)
            this.$emit("input", [...this.value]);
        },
    },
};
</script>

<style lang="scss" scoped>
.v-input--checkbox,
.v-simple-checkbox,
.v-radio {
    ::v-deep {
        .v-input--selection-controls__ripple:before {
            display: none !important;
        }
    }
}
</style>
