var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overflow-hidden"
  }, [_c('v-radio-group', _vm._b({
    attrs: {
      "value": _vm.deliveryType
    },
    on: {
      "change": _vm.input
    }
  }, 'v-radio-group', Object.assign({}, _vm.attrs_controls_common), false), [_c('v-radio', {
    attrs: {
      "label": "단건배송",
      "value": "single"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "정기배송",
      "value": "regular"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }