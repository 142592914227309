<template>
    <div v-if="suppliesEnabled">
        <div v-for="(group, index) in groups" :key="index" :class="{ 'mt-4px mt-md-8px': index != 0 }">
            <v-select v-model="choose[index]" dense outlined hide-details :items="supplies.filter((supply) => supply.name.split(':')[0] == group)" v-bind="{ itemText, itemDisabled }" return-object :placeholder="group" @change="input(choose[index])" disable-lookup> </v-select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Array, default: () => [] }, // carts
        product: { type: Object, default: null },
        sidebar: { type: Boolean, default: false },
        deliveryType: { type: String, default: "single" },
    },
    computed: {
        supplies() {
            return this.product?.supplies || [];
        },
        suppliesEnabled() {
            return this.product?.suppliesEnabled || false;
        },
        groups() {
            var groups = [];
            for (var i in this.supplies) {
                if (groups.indexOf(this.supplies[i].name.split(":")[0]) < 0) groups.push(this.supplies[i].name.split(":")[0]);
            }
            return groups;
        },
    },
    data: () => ({
        choose: [],
    }),
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        },
    },
    methods: {
        init() {
            try {
                for (var i in this.groups) {
                    if (!this.choose[i]) this.$set(this.choose, i, "");
                }
            } catch (error) {
                console.error(error);
            }
        },
        input(supply) {
            if (supply && !this.value.find((cart) => cart._id == supply._id)) {
                const cart = {
                    _product: this.product._id,
                    _supply: supply._id,
                    product: this.product,
                    name: supply.name,
                    price: supply.price,
                    salePrice: supply.salePrice,
                    discountPrice: supply.discountPrice,
                    stock: supply.stock,
                    amount: 1,
                    deliveryType: this.deliveryType
                };
                this.$emit("input", [...this.value, cart]);
            }
        },
        itemText({ name, price, stock }) {
            let text = name;
            if (stock) {
                if (price) {
                    if (0 <= price) text += ` (+${price.format()}원)`;
                    else text += ` (${price.format()}원)`;
                }
            } else text += " (품절)";
            return text;
        },
        itemDisabled({ stock }) {
            return stock == 0;
        },
    },
};
</script>
