<template>
    <v-card v-bind="{ loading }" outlined rounded="md" class="border-color primary">
        <v-expansion-panels flat accordion>
            <v-expansion-panel class="transparent">
                <v-expansion-panel-header expand-icon="mdi-menu-down">
                    <v-row class="row--sm txt txt--xs txt--dark">
                        <v-col>{{ title__maximum }}</v-col>
                        <v-col cols="auto">
                            <span class="font-weight-medium">{{ value__maximum?.format?.() }}{{ unit }}</span>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-divider />
                    <template v-for="({ title, items }, index) in descriptions">
                        <div :key="`description-point-${index}-title`" class="py-8px py-md-16px">
                            <div class="pb-6px pb-md-8px">
                                <span class="txt txt--xs txt--dark">{{ title }}</span>
                            </div>
                            <div v-for="({ text, value }, itemIndex) in items" :key="`description-point-${index}-item-${itemIndex}`">
                                <v-row class="row--sm txt txt--xs">
                                    <v-col>
                                        <v-row class="row--xxs">
                                            <v-col cols="auto">·</v-col>
                                            <v-col> {{ text }} </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="auto" class="txt--dark">
                                        <span class="font-weight-medium">{{ value?.format?.() }}{{ unit }}</span>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </template>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel readonly v-if="isPromoter">
                <v-expansion-panel-header hide-actions expand-icon="mdi-menu-down" class="cursor-default">
                    <v-row class="row--sm txt txt--xs primary--text">
                        <v-col>추천 회원 구매 시 구매금액의 1% 직급포인트 적립</v-col>
                        <v-col cols="auto">
                            <span class="font-weight-medium">{{ value__scorePromotee?.format?.() }}p</span>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-divider class="mx-16px" />
            </v-expansion-panel>
            <v-expansion-panel readonly v-if="isPromoter">
                <v-expansion-panel-header hide-actions expand-icon="mdi-menu-down" class="cursor-default">
                    <v-row class="row--sm txt txt--xs primary--text">
                        <v-col>추천 회원 구매 시 구매금액의 {{ product?.bonus?.amount || 0 }}% 기본보너스 적립</v-col>
                        <v-col cols="auto">
                            <span class="font-weight-medium">{{ value__bonusMaximum?.format?.() }}원</span>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { USER_TYPES } from "@/assets/variables";
import api from "@/api";

export default {
    props: {
        _product: { type: String, default: null },
    },
    data: () => ({
        product: undefined,

        loading: false,
    }),
    computed: {
        ...mapState(["user", "accessToken"]),
        isPromoter() {
            return this.user?.type == USER_TYPES.PROMOTER.value;
        },
        descriptions__point() {
            return [
                {
                    title: "기본 적립",
                    items: [
                        {
                            text: "물품 구매 시 구매금액의 1%",
                            value: Math.floor((this.product?.salePrice || 0) / 1000) * 10,
                            isSumTarget: true,
                        },
                    ],
                },
                {
                    title: "리뷰 적립",
                    items: [
                        {
                            text: "일반 리뷰 시 1%",
                            value: Math.floor((this.product?.salePrice || 0) / 1000) * 10,
                        },
                        {
                            text: "포토 리뷰 작성 시 2%",
                            value: Math.floor((this.product?.salePrice || 0) / 500) * 10,
                            isSumTarget: true,
                        },
                    ],
                },
            ];
        },
        descriptions__score() {
            return [
                {
                    title: "기본 적립",
                    items: [
                        {
                            text: "물품 구매 시 구매금액의 1%",
                            value: Math.floor((this.product?.salePrice || 0) / 100),
                            isSumTarget: true,
                        },
                    ],
                },
                {
                    title: "리뷰 적립",
                    items: [
                        {
                            text: "일반 리뷰 시 1%",
                            value: Math.floor((this.product?.salePrice || 0) / 100),
                        },
                        {
                            text: "포토 리뷰 작성 시 2%",
                            value: Math.floor((this.product?.salePrice || 0) / 50),
                            isSumTarget: true,
                        },
                    ],
                },
            ];
        },
        title__maximum() {
            return this.isPromoter ? "최대직급포인트" : "최대적립금";
        },
        value__maximum() {
            return this.descriptions.flatMap(({ items }) => items).reduce((sum, { value, isSumTarget }) => sum + (isSumTarget ? value : 0), 0);
        },
        descriptions() {
            return this.isPromoter ? this.descriptions__score : this.descriptions__point;
        },
        unit() {
            return this.isPromoter ? "p" : "원";
        },
        value__scorePromotee() {
            return Math.floor((this.product?.salePrice || 0) / 100);
        },
        value__bonusMaximum() {
            return Math.floor(((this.product?.salePrice || 0) / 1000) * (this.product?.bonus?.amount || 0)) * 10;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            if (this.loading) return;
            else this.loading = true;

            if (this.accessToken) await this.getUser();
            try {
                const { product } = await api.v1.shop.products.get({ _id: this._product });
                this.product = product;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-expansion-panel-header,
    .v-expansion-panel--active > .v-expansion-panel-header {
        min-height: 52px;
    }
    .v-expansion-panel-header {
        padding: 16px;
    }
    .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
        color: var(--v-grey-base);
    }

    .v-expansion-panel-content__wrap {
        padding: 0 16px;
    }
}
</style>
