<template>
    <!-- 선택한 옵션 그룹 -->
    <page-section v-if="carts.length != 0" class="page-section--xs pb-0">
        <template #containerImmersive>
            <!-- 옵션 박스 -->
            <div v-for="(cart, index) in carts" :key="index" :class="{ 'mt-4px mt-md-8px': index != 0 }">
                <v-card rounded="md" color="grey lighten-5" class="txt txt--xs">
                    <div class="pa-10px pa-md-16px">
                        <div class="mb-8px mb-md-12px">{{ cart.name }} <span v-if="cart.stock == 0">(품절)</span></div>
                        <v-row no-gutters align="center">
                            <v-col cols="auto">
                                <!-- 옵션 수량 -->
                                <div class="spinner">
                                    <v-btn icon tile class="v-size--xx-small transparent" @click="decAmount(cart)"><v-icon color="grey">mdi-minus</v-icon></v-btn>
                                    <input v-model="cart.amount" class="spinner__num" type="number" @change="changeAmount(cart)" :disabled="!cart.stock" />
                                    <v-btn icon tile class="v-size--xx-small transparent" @click="incAmount(cart)"><v-icon color="grey">mdi-plus</v-icon></v-btn>
                                </div>
                            </v-col>
                            <v-spacer />
                            <v-col cols="auto">
                                <span class="d-flex align-center">
                                    <strong class="font-size-16 font-size-lg-20">{{ (cart.salePrice * cart.amount).format() }}</strong>
                                    <span class="pl-2px">원</span>
                                </span>
                            </v-col>
                            <v-col cols="auto" v-if="cart._option || cart._supply">
                                <v-btn small icon class="transparent mr-n4px mr-md-n10px" @click="remove(index)">
                                    <v-icon class="font-size-inherit grey--text text--lighten-1">mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <!-- <div class="mt-4px mt-md-8px">
                        <v-text-field v-model="cart.requestMessage" dense outlined hide-details placeholder="상품요청사항" @input="emit" />
                    </div> -->
                    </div>
                </v-card>
            </div>
        </template>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        PageSection,
    },
    props: {
        value: { type: Array, default: () => [] }, // selected
    },
    data: () => ({
        carts: [],
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.carts = [...this.value];
        },
        emit() {
            this.$emit("input", this.carts);
        },
        incAmount(cart) {
            if (cart.amount < cart.stock) cart.amount++;
            this.emit();
        },
        decAmount(cart) {
            if (1 < cart.amount) cart.amount--;
            this.emit();
        },
        changeAmount(cart) {
            if (cart.amount < 1) cart.amount = 1;
            if (cart.stock < cart.amount) cart.amount = cart.stock;
            this.emit();
        },
        remove(index) {
            this.carts.splice(index, 1);
            this.emit();
        },
    },
};
</script>
