var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.suppliesEnabled ? _c('div', _vm._l(_vm.groups, function (group, index) {
    return _c('div', {
      key: index,
      class: {
        'mt-4px mt-md-8px': index != 0
      }
    }, [_c('v-select', _vm._b({
      attrs: {
        "dense": "",
        "outlined": "",
        "hide-details": "",
        "items": _vm.supplies.filter(function (supply) {
          return supply.name.split(':')[0] == group;
        }),
        "return-object": "",
        "placeholder": group,
        "disable-lookup": ""
      },
      on: {
        "change": function ($event) {
          return _vm.input(_vm.choose[index]);
        }
      },
      model: {
        value: _vm.choose[index],
        callback: function ($$v) {
          _vm.$set(_vm.choose, index, $$v);
        },
        expression: "choose[index]"
      }
    }, 'v-select', {
      itemText: _vm.itemText,
      itemDisabled: _vm.itemDisabled
    }, false))], 1);
  }), 0) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }