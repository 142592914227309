var render = function render(){
  var _vm$value__maximum, _vm$value__maximum$fo, _vm$value__scorePromo, _vm$value__scorePromo2, _vm$product, _vm$product$bonus, _vm$value__bonusMaxim, _vm$value__bonusMaxim2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "border-color primary",
    attrs: {
      "outlined": "",
      "rounded": "md"
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-expansion-panels', {
    attrs: {
      "flat": "",
      "accordion": ""
    }
  }, [_c('v-expansion-panel', {
    staticClass: "transparent"
  }, [_c('v-expansion-panel-header', {
    attrs: {
      "expand-icon": "mdi-menu-down"
    }
  }, [_c('v-row', {
    staticClass: "row--sm txt txt--xs txt--dark"
  }, [_c('v-col', [_vm._v(_vm._s(_vm.title__maximum))]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s((_vm$value__maximum = _vm.value__maximum) === null || _vm$value__maximum === void 0 ? void 0 : (_vm$value__maximum$fo = _vm$value__maximum.format) === null || _vm$value__maximum$fo === void 0 ? void 0 : _vm$value__maximum$fo.call(_vm$value__maximum)) + _vm._s(_vm.unit))])])], 1)], 1), _c('v-expansion-panel-content', [_c('v-divider'), _vm._l(_vm.descriptions, function (_ref, index) {
    var title = _ref.title,
      items = _ref.items;
    return [_c('div', {
      key: `description-point-${index}-title`,
      staticClass: "py-8px py-md-16px"
    }, [_c('div', {
      staticClass: "pb-6px pb-md-8px"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark"
    }, [_vm._v(_vm._s(title))])]), _vm._l(items, function (_ref2, itemIndex) {
      var _value$format;
      var text = _ref2.text,
        value = _ref2.value;
      return _c('div', {
        key: `description-point-${index}-item-${itemIndex}`
      }, [_c('v-row', {
        staticClass: "row--sm txt txt--xs"
      }, [_c('v-col', [_c('v-row', {
        staticClass: "row--xxs"
      }, [_c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_vm._v("·")]), _c('v-col', [_vm._v(" " + _vm._s(text) + " ")])], 1)], 1), _c('v-col', {
        staticClass: "txt--dark",
        attrs: {
          "cols": "auto"
        }
      }, [_c('span', {
        staticClass: "font-weight-medium"
      }, [_vm._v(_vm._s(value === null || value === void 0 ? void 0 : (_value$format = value.format) === null || _value$format === void 0 ? void 0 : _value$format.call(value)) + _vm._s(_vm.unit))])])], 1)], 1);
    })], 2)];
  })], 2)], 1), _vm.isPromoter ? _c('v-expansion-panel', {
    attrs: {
      "readonly": ""
    }
  }, [_c('v-expansion-panel-header', {
    staticClass: "cursor-default",
    attrs: {
      "hide-actions": "",
      "expand-icon": "mdi-menu-down"
    }
  }, [_c('v-row', {
    staticClass: "row--sm txt txt--xs primary--text"
  }, [_c('v-col', [_vm._v("추천 회원 구매 시 구매금액의 1% 직급포인트 적립")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s((_vm$value__scorePromo = _vm.value__scorePromotee) === null || _vm$value__scorePromo === void 0 ? void 0 : (_vm$value__scorePromo2 = _vm$value__scorePromo.format) === null || _vm$value__scorePromo2 === void 0 ? void 0 : _vm$value__scorePromo2.call(_vm$value__scorePromo)) + "p")])])], 1)], 1), _c('v-divider', {
    staticClass: "mx-16px"
  })], 1) : _vm._e(), _vm.isPromoter ? _c('v-expansion-panel', {
    attrs: {
      "readonly": ""
    }
  }, [_c('v-expansion-panel-header', {
    staticClass: "cursor-default",
    attrs: {
      "hide-actions": "",
      "expand-icon": "mdi-menu-down"
    }
  }, [_c('v-row', {
    staticClass: "row--sm txt txt--xs primary--text"
  }, [_c('v-col', [_vm._v("추천 회원 구매 시 구매금액의 " + _vm._s(((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : (_vm$product$bonus = _vm$product.bonus) === null || _vm$product$bonus === void 0 ? void 0 : _vm$product$bonus.amount) || 0) + "% 기본보너스 적립")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s((_vm$value__bonusMaxim = _vm.value__bonusMaximum) === null || _vm$value__bonusMaxim === void 0 ? void 0 : (_vm$value__bonusMaxim2 = _vm$value__bonusMaxim.format) === null || _vm$value__bonusMaxim2 === void 0 ? void 0 : _vm$value__bonusMaxim2.call(_vm$value__bonusMaxim)) + "원")])])], 1)], 1)], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }